import { journal } from "../../js/path";

export default {
  data() {
    return {
      pageNo: null,
      status: false,
      oldForm: {},
      activeTab: "all",
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      tableData: [],
      currentPage: 1,
      params: "",
      key: 0,
      form: {},
      image_name: "",
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "journal",
          label: "Journal",
        },
        {
          key: "logo_image",
        },
        {
          key: "upload",
        },
      ],
      imageModal: false,
      image_file: "",
      journal_name: "",
    };
  },
  computed: {
    geturl() {
      return `${this.url + this.form.url_link}`;
    },
  },
  methods: {
    searchFor() {
      if (this.filter.length > 1) {
        this.currentPage = 1;
        this.fetchData(this.activeTab);
      } else if (this.filter.length == 0) {
        this.currentPage = 1;
        this.fetchData(this.activeTab);
      }
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData(this.activeTab);
        this.currentPage = this.pageNo;
      }
    },
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.currentPage = 1;
          this.fetchData(this.activeTab);
        }
      } else if (this.filter.length == 0) {
        this.currentPage = 1;
        this.fetchData(this.activeTab);
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchData() {
      let url = null;
      if (this.filter != null && this.filter != "") {
        url = journal.journalList + "?search=" + this.filter;
      } else {
        url = journal.journalList + "?filter=";
      }

      url += this.params;
      this.getUrl();
      this.$store.commit("loader/updateStatus", true);
      try {
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          this.tableData = responseData;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
      this.key += 1;
    },

    getUrl() {
      var url = new URL(window.location);
      if (this.activeTab == null || this.activeTab == "") {
        this.activeTab = "all";
      }
      url.searchParams.has("filter")
        ? url.searchParams.set("filter", this.activeTab)
        : url.searchParams.append("filter", this.activeTab);
      if (this.filter != null && this.filter != "") {
        url.searchParams.has("search")
          ? url.searchParams.set("search", this.filter)
          : url.searchParams.append("search", this.filter);
      } else {
        url.searchParams.delete("search");
      }
      if (this.currentPage != null && this.currentPage != "") {
        url.searchParams.has("page")
          ? url.searchParams.set("page", this.currentPage)
          : url.searchParams.append("page", this.currentPage);
      } else {
        url.searchParams.set("page", 1);
      }
      url.search = url.searchParams;
      url = url.toString();
      history.pushState({}, null, url);
    },

    showImageModal(journal) {
      this.imageModal = true;
      this.journal_name = journal;
    },

    clearImageModal() {
      this.imageModal = false;
      this.image = null;
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
        const maxSize = 10 * 1024 * 1024; // 10 MB

        // Check if the file type is valid
        if (!allowedTypes.includes(file.type)) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Only .jpg, .jpeg, .png allowed",
          });
          this.$refs.fileInput.value = "";
        }
        // Check if the file size is greater than 10 MB
        else if (file.size > maxSize) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Max file size should be 10 MB",
          });
          this.$refs.fileInput.value = "";
        } else {
          console.log("File is valid:", file);
          this.image_file = file ?? "";
        }
      }
    },

    readFile(e, txt) {
      if (txt == "image_name") {
        this.image_name = e.target.files[0];
        if (this.image_name.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB",
          });
          this.$refs.image_name.reset();
          this.image_name_url = "";
          this.edit.image_name_url = "";
          this.image_name = "";
          return false;
        } else {
          this.image_name_url = URL.createObjectURL(this.image_name);
          this.edit.image_name_url = URL.createObjectURL(this.image_name);
        }
        return true;
      } else if (txt == "image_name_indonesia") {
        this.image_name_indonesia = e.target.files[0];
        if (this.image_name_indonesia.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB",
          });
          this.$refs.image_name_indonesia.reset();
          this.image_name_indonesia_url = "";
          this.edit.image_name_indonesia_url = "";
          this.image_name_indonesia = "";
          return false;
        } else {
          this.image_name_indonesia_url = URL.createObjectURL(
            this.image_name_indonesia
          );
          this.edit.image_name_indonesia_url = "";
        }
        return true;
      }
    },

    async uploadLogoFile() {
      try {
        let dataAppend = new FormData();
        if (this.image_file) {
          dataAppend.append("logo_image", this.image_file);
          dataAppend.append("journal", this.journal_name);
        }
        const url = `${journal?.journalLogoUpload}`;
        const data = await this.postRequest(url, dataAppend);
        if (data?.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data?.message ?? "Logo uploaded successfully",
          });
          this.video_file = null;
          this.currentId = null;
        } else {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Something went wrong",
          });
        }
        this.$nextTick(() => {
          this.$bvModal.hide("imageModal");
        });
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Something went wrong",
        });
        this.$nextTick(() => {
          this.$bvModal.hide("imageModal");
        });
      }
      this.imageModal = false;
      this.fetchData();
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (typeof value != undefined) {
          this.params = `&page=${value}`;
          this.fetchData(this.activeTab);
          this.pageNo = this.currentPage;
        }
      },
    },
    status(v) {
      if (v == 1) {
        this.fetchAllCommunity();
      } else {
        if (this.$route.name == "add-video") {
          this.form.community_selected = [];
        } else if (this.$route.name == "edit-video") {
          this.form.community_selected = this.oldForm.community_selected;
        }
      }
    },
  },
  mounted() {
    this.activeTab = "all";
    if (this.$route.query.filter) {
      this.activeTab = this.$route.query.filter;
    }
    if (this.$route.query.search) {
      this.filter = this.$route.query.search;
    }
    if (this.$route.query.page && this.$route.query.page != 1) {
      this.currentPage = parseInt(this.$route.query.page);
    } else {
      this.fetchData(this.activeTab);
    }
  },
};