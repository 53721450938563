<template>
  <Layout>
    <PageHeader :title="title" :items="items"></PageHeader>
    <div class="row" id="video">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center mb-4">
              <div class="col-md-6">
                <div class="form-inline navbar-search">
                  <div class="input-group">
                    <input name="title" class="form-control bg-light border-0 small" placeholder="Search..."
                      aria-label="Search" aria-describedby="basic-addon2" value="" v-model="filter"
                      v-on:keyup="search" />
                    <div class="input-group-append">
                      <button class="btn btn-search" @click="searchFor">
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="all-tabs">
                  <div :class="activeTab == 'all' ? ' tab-options' : ''"
                    @click.prevent="fetchData('all'); currentPage = 1" class="mx-2 opt">
                    All
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive mb-0">
              <b-table striped bordered :items="tableData.data" :fields="fields" :sort-by.sync="sortBy" :per-page="0"
                :current-page="currentPage" :sort-desc.sync="sortDesc" :filter-included-fields="filterOn"
                @filtered="onFiltered" responsive="sm" class="table-bordered table-hover" :key="key">
                <template v-slot:cell(logo_image)="row">
                  <div v-if="row.item.logo_image">
                    <img :src="row.item.logo_image" alt="Logo" class="video_thumbnail" />
                  </div>
                  <div class="" v-else>
                    --
                  </div>
                </template>
                <template v-can="'journal-list'" v-slot:cell(upload)="row">
                  <i class="fa fa-upload text-primary" @click="showImageModal(row.item.journal)"
                    :disabled="row.item.logo_image ? false : true"></i>
                </template>
              </b-table>
            </div>
            <template v-if="tableData.total_pages > 1">
              <div class="data_paginate">
                <div class="dataTables_paginate paging_simple_numbers d-flex justify-content-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination v-model="currentPage" first-number last-number :total-rows="tableData.total"
                      :total-pages="tableData.total_pages" :per-page="tableData.per_page"></b-pagination>
                  </ul>
                </div>
                <div class="form-inline navbar-search">
                  <span class="mr-2">Go to a Particular Paginated Page :</span>
                  <div class="input-group">
                    <b-form-input id="case" v-model="pageNo" placeholder="Enter Page No" class="in-width">
                    </b-form-input>
                    <div class="input-group-append">
                      <button class="btn btn-search" @click="filterPage">
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>

        <b-modal id="bvVideoUploadModal" ref="videoUpload" title="Upload Logo Image [Max 10MB allowed]"
          v-model="imageModal" no-close-on-backdrop centered hide-header-close hide-footer @hidden="clearImageModal">
          <div class="">
            <b-form-file id="input-5" ref="fileInput" accept=".png" placeholder="Choose a file or drop it here..."
              @change="handleFileUpload($event)"></b-form-file>

          </div>
          <div class="mt-4 d-flex justify-content-end">
            <b-button @click="clearImageModal" variant="secondary" class="mr-2" v-if="!isProgress">
              Cancel
            </b-button>
            <b-button @click="uploadLogoFile" variant="primary" :disabled="!image_file">
              Upload
            </b-button>
          </div>
        </b-modal>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import videoMixin from "../../../mixins/ModuleJs/journal";

export default {
  mixins: [MixinRequest, videoMixin],
  data() {
    return {
      title: "Journal Logo",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "List",
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
};
</script>
